import { apiClient, getHeaders } from '@/services/axiosConfig'

export default {
  getHomeProducts (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getDetailProduct (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/products/${payload.slug}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getFulfilledDetailProduct (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/products/${payload.slug}/fulfilled-by-paninti`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getNearbyLocationProduct (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/products/nearby-location',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getMetaProduct (auth, slug) {
    return apiClient({
      method: 'post',
      url: '/v1/products/' + slug + '/meta-scripts',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  getProductAffiliate (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/affiliate-products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductUnAffiliated  (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/products/unaffiliated',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  addProductAffiliate (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/affiliate-products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getRecomendationProduct (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/products/recommendation',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getTopPickProducts (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/products/top-picks',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductNotReviewed (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/order-products/not-reviewed',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getNewProducts (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/products/new-products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getMostReviewedProducts (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/products/most-reviewed',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getBestSellerHomePage (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/products/homepage-best-seller',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductReviews (auth, id, payload) {
    return apiClient({
      method: 'post',
      url: `v1/products/${id}/reviews`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductReviewFiles (auth, id, payload) {
    return apiClient({
      method: 'post',
      url: `v1/products/${id}/reviews/files`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getLastSeenProducts (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/products/last-seen',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductsLastSeen (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/products/last-seen',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  deleteProductsLastSeen (auth, payload) {
    return apiClient({
      method: 'delete',
      url: '/v1/general/products/last-seen',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductSection (auth, section, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/products/' + section,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductsSale (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/products/on-sale',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductsEventSale (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductsPreSaleSession (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/event-sale-sessions',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductWishlist (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/users/my-product-wishlists',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductsWishlist (auth, payload) {
    return apiClient({
      method: 'get',
      url: '/v1/general/users/my-product-wishlists',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductCategory (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/catalogs/category/products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getEstimatePrice (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/products/get-price-with-shipping-cost',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getAffiliateTopProducts (auth, payload) {
    return apiClient({
      method: 'get',
      url: `v1/users/${payload.username}/affiliate-top-products`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getAffiliateAllProduct (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/users/${payload.username}/affiliate-products`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getProductBestSellingMonthly (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/products/best-selling-this-month',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getPopularAffiliateMonthly (auth, payload) {
    return apiClient({
      method: 'post',
      url: 'v1/products/affiliate-popular-this-month',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  deleteAffiliateProduct (auth, payload) {
    return apiClient({
      method: 'delete',
      url: '/v1/general/affiliate-products',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getCatalogAffiliate (auth, payload) {
    return apiClient({
      method: 'post',
      url: `v1/products/${payload.section}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
