import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eb321220 = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _fbf8531a = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _e99cc658 = () => interopDefault(import('../pages/check-health/index.vue' /* webpackChunkName: "pages/check-health/index" */))
const _58cbd519 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _4edd0caa = () => interopDefault(import('../pages/clearance-sale/index.vue' /* webpackChunkName: "pages/clearance-sale/index" */))
const _d792e54c = () => interopDefault(import('../pages/flashsale/index.vue' /* webpackChunkName: "pages/flashsale/index" */))
const _6a4830b0 = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f770e21a = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _68ed484d = () => interopDefault(import('../pages/nearby-partner/index.vue' /* webpackChunkName: "pages/nearby-partner/index" */))
const _5a1b3c1a = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _8f5fd24c = () => interopDefault(import('../pages/sale/index.vue' /* webpackChunkName: "pages/sale/index" */))
const _6946a116 = () => interopDefault(import('../pages/account/address/index.vue' /* webpackChunkName: "pages/account/address/index" */))
const _2c8887e4 = () => interopDefault(import('../pages/account/affiliate/index.vue' /* webpackChunkName: "pages/account/affiliate/index" */))
const _3d2e6e11 = () => interopDefault(import('../pages/account/bank-account/index.vue' /* webpackChunkName: "pages/account/bank-account/index" */))
const _65180296 = () => interopDefault(import('../pages/account/change-password.vue' /* webpackChunkName: "pages/account/change-password" */))
const _415724d8 = () => interopDefault(import('../pages/account/home/index.vue' /* webpackChunkName: "pages/account/home/index" */))
const _61779303 = () => interopDefault(import('../pages/account/lastseen.vue' /* webpackChunkName: "pages/account/lastseen" */))
const _052583a3 = () => interopDefault(import('../pages/account/not-reviewed.vue' /* webpackChunkName: "pages/account/not-reviewed" */))
const _62bafbdd = () => interopDefault(import('../pages/account/notification.vue' /* webpackChunkName: "pages/account/notification" */))
const _44b25707 = () => interopDefault(import('../pages/account/payment/index.vue' /* webpackChunkName: "pages/account/payment/index" */))
const _d613d8e4 = () => interopDefault(import('../pages/account/point.vue' /* webpackChunkName: "pages/account/point" */))
const _fdfc822c = () => interopDefault(import('../pages/account/profile/index.vue' /* webpackChunkName: "pages/account/profile/index" */))
const _7eb328b7 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _4e3e1bde = () => interopDefault(import('../pages/affiliate/create-affiliate/index.vue' /* webpackChunkName: "pages/affiliate/create-affiliate/index" */))
const _ffb6bf18 = () => interopDefault(import('../pages/affiliate/home/index.vue' /* webpackChunkName: "pages/affiliate/home/index" */))
const _16b34f2e = () => interopDefault(import('../pages/affiliate/partner/index.vue' /* webpackChunkName: "pages/affiliate/partner/index" */))
const _470e4684 = () => interopDefault(import('../pages/account/address/add.vue' /* webpackChunkName: "pages/account/address/add" */))
const _10353222 = () => interopDefault(import('../pages/account/affiliate/commission/index.vue' /* webpackChunkName: "pages/account/affiliate/commission/index" */))
const _31cd9fb3 = () => interopDefault(import('../pages/account/affiliate/product-list/index.vue' /* webpackChunkName: "pages/account/affiliate/product-list/index" */))
const _1e6caa96 = () => interopDefault(import('../pages/account/payment/add.vue' /* webpackChunkName: "pages/account/payment/add" */))
const _e3a1f7b6 = () => interopDefault(import('../pages/account/profile/edit/index.vue' /* webpackChunkName: "pages/account/profile/edit/index" */))
const _60fec932 = () => interopDefault(import('../pages/account/shop/my-order/index.vue' /* webpackChunkName: "pages/account/shop/my-order/index" */))
const _105aa39e = () => interopDefault(import('../pages/account/shop/order-review/index.vue' /* webpackChunkName: "pages/account/shop/order-review/index" */))
const _c355a982 = () => interopDefault(import('../pages/account/shop/wishlist/index.vue' /* webpackChunkName: "pages/account/shop/wishlist/index" */))
const _94339a50 = () => interopDefault(import('../pages/account/affiliate/commission/withdrawal/index.vue' /* webpackChunkName: "pages/account/affiliate/commission/withdrawal/index" */))
const _e117c232 = () => interopDefault(import('../pages/account/affiliate/product-list/add-product/index.vue' /* webpackChunkName: "pages/account/affiliate/product-list/add-product/index" */))
const _2026af6a = () => interopDefault(import('../pages/account/shop/my-order/detail/_order_id/index.vue' /* webpackChunkName: "pages/account/shop/my-order/detail/_order_id/index" */))
const _62e328e6 = () => interopDefault(import('../pages/account/shop/my-order/detail/_order_id/invoice.vue' /* webpackChunkName: "pages/account/shop/my-order/detail/_order_id/invoice" */))
const _f5174d8e = () => interopDefault(import('../pages/account/shop/my-order/detail/_order_id/invoice-backup.vue' /* webpackChunkName: "pages/account/shop/my-order/detail/_order_id/invoice-backup" */))
const _66480b62 = () => interopDefault(import('../pages/account/address/edit/_id.vue' /* webpackChunkName: "pages/account/address/edit/_id" */))
const _d6c41a5c = () => interopDefault(import('../pages/affiliate/products/_section/index.vue' /* webpackChunkName: "pages/affiliate/products/_section/index" */))
const _8e413de4 = () => interopDefault(import('../pages/password/reset/_token.vue' /* webpackChunkName: "pages/password/reset/_token" */))
const _1d364be5 = () => interopDefault(import('../pages/review/add/_id.vue' /* webpackChunkName: "pages/review/add/_id" */))
const _59d8fab9 = () => interopDefault(import('../pages/c1/_slug.vue' /* webpackChunkName: "pages/c1/_slug" */))
const _2284a50c = () => interopDefault(import('../pages/c2/_slug.vue' /* webpackChunkName: "pages/c2/_slug" */))
const _f8bb3f8a = () => interopDefault(import('../pages/c3/_slug.vue' /* webpackChunkName: "pages/c3/_slug" */))
const _7f822113 = () => interopDefault(import('../pages/linktree/_id.vue' /* webpackChunkName: "pages/linktree/_id" */))
const _5ec92789 = () => interopDefault(import('../pages/page/_section/index.vue' /* webpackChunkName: "pages/page/_section/index" */))
const _c5e9985a = () => interopDefault(import('../pages/partner/_id.vue' /* webpackChunkName: "pages/partner/_id" */))
const _7df59bb0 = () => interopDefault(import('../pages/payment-confirmation/_order_id.vue' /* webpackChunkName: "pages/payment-confirmation/_order_id" */))
const _7fa85ef8 = () => interopDefault(import('../pages/payment-detail/_order_id.vue' /* webpackChunkName: "pages/payment-detail/_order_id" */))
const _73ba53cd = () => interopDefault(import('../pages/product/_slug/index.vue' /* webpackChunkName: "pages/product/_slug/index" */))
const _c9909ad8 = () => interopDefault(import('../pages/products/_section/index.vue' /* webpackChunkName: "pages/products/_section/index" */))
const _6da39676 = () => interopDefault(import('../pages/sale/_id/index.vue' /* webpackChunkName: "pages/sale/_id/index" */))
const _0b91d4e6 = () => interopDefault(import('../pages/users/_slug/index.vue' /* webpackChunkName: "pages/users/_slug/index" */))
const _4af78ad2 = () => interopDefault(import('../pages/sale/_id/section/_idSection.vue' /* webpackChunkName: "pages/sale/_id/section/_idSection" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/affiliate",
    component: _eb321220,
    name: "affiliate"
  }, {
    path: "/cart",
    component: _fbf8531a,
    name: "cart"
  }, {
    path: "/check-health",
    component: _e99cc658,
    name: "check-health"
  }, {
    path: "/checkout",
    component: _58cbd519,
    name: "checkout"
  }, {
    path: "/clearance-sale",
    component: _4edd0caa,
    name: "clearance-sale"
  }, {
    path: "/flashsale",
    component: _d792e54c,
    name: "flashsale"
  }, {
    path: "/forgot-password",
    component: _6a4830b0,
    name: "forgot-password"
  }, {
    path: "/login",
    component: _57062649,
    name: "login"
  }, {
    path: "/maintenance",
    component: _f770e21a,
    name: "maintenance"
  }, {
    path: "/nearby-partner",
    component: _68ed484d,
    name: "nearby-partner"
  }, {
    path: "/register",
    component: _5a1b3c1a,
    name: "register"
  }, {
    path: "/sale",
    component: _8f5fd24c,
    name: "sale"
  }, {
    path: "/account/address",
    component: _6946a116,
    name: "account-address"
  }, {
    path: "/account/affiliate",
    component: _2c8887e4,
    name: "account-affiliate"
  }, {
    path: "/account/bank-account",
    component: _3d2e6e11,
    name: "account-bank-account"
  }, {
    path: "/account/change-password",
    component: _65180296,
    name: "account-change-password"
  }, {
    path: "/account/home",
    component: _415724d8,
    name: "account-home"
  }, {
    path: "/account/lastseen",
    component: _61779303,
    name: "account-lastseen"
  }, {
    path: "/account/not-reviewed",
    component: _052583a3,
    name: "account-not-reviewed"
  }, {
    path: "/account/notification",
    component: _62bafbdd,
    name: "account-notification"
  }, {
    path: "/account/payment",
    component: _44b25707,
    name: "account-payment"
  }, {
    path: "/account/point",
    component: _d613d8e4,
    name: "account-point"
  }, {
    path: "/account/profile",
    component: _fdfc822c,
    name: "account-profile"
  }, {
    path: "/account/wishlist",
    component: _7eb328b7,
    name: "account-wishlist"
  }, {
    path: "/affiliate/create-affiliate",
    component: _4e3e1bde,
    name: "affiliate-create-affiliate"
  }, {
    path: "/affiliate/home",
    component: _ffb6bf18,
    name: "affiliate-home"
  }, {
    path: "/affiliate/partner",
    component: _16b34f2e,
    name: "affiliate-partner"
  }, {
    path: "/account/address/add",
    component: _470e4684,
    name: "account-address-add"
  }, {
    path: "/account/affiliate/commission",
    component: _10353222,
    name: "account-affiliate-commission"
  }, {
    path: "/account/affiliate/product-list",
    component: _31cd9fb3,
    name: "account-affiliate-product-list"
  }, {
    path: "/account/payment/add",
    component: _1e6caa96,
    name: "account-payment-add"
  }, {
    path: "/account/profile/edit",
    component: _e3a1f7b6,
    name: "account-profile-edit"
  }, {
    path: "/account/shop/my-order",
    component: _60fec932,
    name: "account-shop-my-order"
  }, {
    path: "/account/shop/order-review",
    component: _105aa39e,
    name: "account-shop-order-review"
  }, {
    path: "/account/shop/wishlist",
    component: _c355a982,
    name: "account-shop-wishlist"
  }, {
    path: "/account/affiliate/commission/withdrawal",
    component: _94339a50,
    name: "account-affiliate-commission-withdrawal"
  }, {
    path: "/account/affiliate/product-list/add-product",
    component: _e117c232,
    name: "account-affiliate-product-list-add-product"
  }, {
    path: "/account/shop/my-order/detail/:order_id",
    component: _2026af6a,
    name: "account-shop-my-order-detail-order_id"
  }, {
    path: "/account/shop/my-order/detail/:order_id/invoice",
    component: _62e328e6,
    name: "account-shop-my-order-detail-order_id-invoice"
  }, {
    path: "/account/shop/my-order/detail/:order_id/invoice-backup",
    component: _f5174d8e,
    name: "account-shop-my-order-detail-order_id-invoice-backup"
  }, {
    path: "/account/address/edit/:id?",
    component: _66480b62,
    name: "account-address-edit-id"
  }, {
    path: "/affiliate/products/:section",
    component: _d6c41a5c,
    name: "affiliate-products-section"
  }, {
    path: "/password/reset/:token?",
    component: _8e413de4,
    name: "password-reset-token"
  }, {
    path: "/review/add/:id?",
    component: _1d364be5,
    name: "review-add-id"
  }, {
    path: "/c1/:slug?",
    component: _59d8fab9,
    name: "c1-slug"
  }, {
    path: "/c2/:slug?",
    component: _2284a50c,
    name: "c2-slug"
  }, {
    path: "/c3/:slug?",
    component: _f8bb3f8a,
    name: "c3-slug"
  }, {
    path: "/linktree/:id?",
    component: _7f822113,
    name: "linktree-id"
  }, {
    path: "/page/:section",
    component: _5ec92789,
    name: "page-section"
  }, {
    path: "/partner/:id?",
    component: _c5e9985a,
    name: "partner-id"
  }, {
    path: "/payment-confirmation/:order_id?",
    component: _7df59bb0,
    name: "payment-confirmation-order_id"
  }, {
    path: "/payment-detail/:order_id?",
    component: _7fa85ef8,
    name: "payment-detail-order_id"
  }, {
    path: "/product/:slug",
    component: _73ba53cd,
    name: "product-slug"
  }, {
    path: "/products/:section",
    component: _c9909ad8,
    name: "products-section"
  }, {
    path: "/sale/:id",
    component: _6da39676,
    name: "sale-id"
  }, {
    path: "/users/:slug",
    component: _0b91d4e6,
    name: "users-slug"
  }, {
    path: "/sale/:id/section/:idSection?",
    component: _4af78ad2,
    name: "sale-id-section-idSection"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
